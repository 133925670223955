<script>
    import { onMount } from "svelte";
    import { createEventDispatcher } from "svelte";

      const dispatch = createEventDispatcher(); // Initialize the dispatcher

    let games = [];
    let loading = true;
    let error = null;
    let weeks = [];
    let currentWeek = "";
    let selectedWeek = "";

    onMount(async () => {
        await fetchGamesForWeek();

    });

    async function fetchGamesForWeek(week_num = "") {
        loading = true;
        error = null;
        games = [];

        try {
            const url = week_num
                ? `https://api.taylorswayze.com/games/${week_num}`
                : "https://api.taylorswayze.com/games/";
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch games for week ${week_num}: ${response.status}`,
                );
            }

            const data = await response.json();
            console.log("Fetched data:", data); // Log the full response data for debugging

            // Set the games and weeks if valid data is returned
            games = data.games || [];
            weeks = data.weeks || []; // Set weeks from API or default to empty array
            currentWeek = data.current_week; // Set the current week from the API response
            selectedWeek = currentWeek; // Default the selected week to the current week
        } catch (err) {
            console.error("Error fetching games:", err);
            error = err.message; // Set the error state
        } finally {
            loading = false; // Hide loading state after data is fetched
        }
    }


    $: {
        if (games.length > 0) {
            console.log("Games have been updated:", games);
        }
    }

    function handleWeekChange(event) {
        selectedWeek = event.target.value;
        console.log("Selected week:", selectedWeek); // Debugging: log the selected week number

        if (selectedWeek !== currentWeek) {
            fetchGamesForWeek(selectedWeek); // Fetch games for the selected week

        }
    }

    async function fetchTeamSchedule(team_id) {
    try {

        const response = await fetch(`https://api.taylorswayze.com/team-schedule/${team_id}`);
      if (response.ok) {
        const data = await response.json();
        games = data.schedule;
      } else {
        console.error('Failed to fetch team schedule:', response.status);
      }
    } catch (error) {
      console.error('Error fetching team schedule:', error);
    }
  }

  function handleTeamClick(team_id) {
      dispatch("teamSelect", team_id);
    }

</script>




<div class="app">
    <h1>Games for Week {selectedWeek}</h1>
  <!-- Week Selector -->
  <div class="week-selector">
    <label for="week">Select Week:</label>
    <select id="week" bind:value={selectedWeek} on:change={handleWeekChange}>
      {#each weeks as week}
        <option value={week} selected={week == currentWeek}>{week}</option>
      {/each}
    </select>
  </div>

  <!-- Game List -->
  <div class="game-list">
    {#if loading}
      <p>Loading games...</p>
    {:else if error}
      <p>Error: {error}</p>
    {:else if games.length > 0}
      <ul>
        {#each games as game}
          <div class="game-card">
            <li>
              <div class="team-row">
                <div class="team-box">
                  <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>
                    <img class="team-logo" src="./logos/{game.away_team_logo}" alt={game.away_team} />
                  </a>
                  <div class="team-name">
                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>{game.away_team}</a>
                  </div>
                  <div class="win-prob">
                    ({game.away_team_record})<br>
                    Win Prob: {game.away_win_prob}%
                    </div>
                </div>

                <div class="team-box">
                    {game.game_datetime}<br />{game.odds}<br /><sup>Last updated: {game.odds_last_updated}</sup>
                    </div>

                <div class="team-box">
                  <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>
                    <img class="team-logo" src="./logos/{game.home_team_logo}" alt={game.home_team} />
                  </a>
                  <div class="team-name">
                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>{game.home_team}</a>
                  </div>
                  <div class="win-prob">
                    ({game.home_team_record})<br>
                    Win Prob: {game.home_win_prob}%
                    </div>
                </div>
              </div>
            </li>
          </div>
        {/each}
      </ul>
    {:else}
      <p>No games available.</p>
    {/if}
  </div>
</div>
