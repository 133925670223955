<!-- App.svelte -->
<script>
    import GameDisplay from './GameDisplay.svelte';
  import GameList from './GameDisplay.svelte'; // Assuming you have a GameList component
  import '../public/global.css';

</script>

<div id="app">
  <div class="container">
    <h1>Games</h1>
    <GameDisplay />
  </div>
</div>
