<script>
  import { createEventDispatcher } from "svelte";
 import { onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export let teamId;

  let games = [];
  let teamName = "";
  let loading = true;
  let error = null;

  onMount(async () => {
    console.log('Received teamId:', teamId);
    try {
     const response = await fetch(`https://api.taylorswayze.com/team-schedule/${teamId}`);
      if (!response.ok) throw new Error("Failed to fetch team schedule");
      const data = await response.json();
      games = data.schedule;
      teamName = data.team;
    } catch (err) {
      error = err.message;
    } finally {
      loading = false;
    }
  });

  function goBack() {
    dispatch("back"); // Notify parent to go back
  }

  function handleTeamClick(team_id) {
        dispatch("teamSelect", team_id);
      }

</script>




<div class="app">
    <h1>Schedule for {teamName}</h1>


    <!-- Game List -->
    <div class="game-list">
      {#if loading}
        <p>Loading games...</p>
      {:else if error}
        <p>Error: {error}</p>
      {:else if games.length > 0}
        <ul>
          {#each games as game}
            <div class="game-card">
              <li>
                <div class="team-row">
                  <div class="team-box">
                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>
                      <img class="team-logo" src="./logos/{game.away_team_logo}" alt={game.away_team} />
                    </a>
                    <div class="team-name">
                      <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>{game.away_team}</a>
                    </div>
                    <div class="win-prob">
                      ({game.away_team_record})<br>
                      Win Prob: {game.away_win_prob}%
                      </div>
                  </div>

                  <div class="team-box">
                      {game.game_datetime}<br />{game.odds}<br /><sup>Last updated: {game.odds_last_updated}</sup>
                      </div>

                  <div class="team-box">
                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>
                      <img class="team-logo" src="./logos/{game.home_team_logo}" alt={game.home_team} />
                    </a>
                    <div class="team-name">
                      <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>{game.home_team}</a>
                    </div>
                    <div class="win-prob">
                      ({game.home_team_record})<br>
                      Win Prob: {game.home_win_prob}%
                      </div>
                  </div>
                </div>
              </li>
            </div>
          {/each}
        </ul>
      {:else}
        <p>No games available.</p>
      {/if}
    </div>
  </div>
