<!-- App.svelte -->
<script>
    import GameDisplay from './GameDisplay.svelte';
  import TeamSchedule from './TeamSchedule.svelte'; // Assuming you have a GameList component
  import '../public/global.css';

  let currentComponent = "GameDisplay";
  let teamId = null;

  function showTeamSchedule(selectedTeamId) {
      teamId = selectedTeamId.detail;
      currentComponent = "TeamSchedule";
    }

    function backToGameDisplay() {
      currentComponent = "GameDisplay";
      teamId = null;
    }

</script>

<div id="app">
  <div class="container">
    {#if currentComponent === "GameDisplay"}
      <GameDisplay on:teamSelect={showTeamSchedule} />
    {:else if currentComponent === "TeamSchedule"}
      <TeamSchedule teamId={teamId} on:back={backToGameDisplay} />
    {/if}
  </div>
</div>
