<script>
    import { onMount } from "svelte";

    let games = [];
    let loading = true;
    let error = null;
    let weeks = [];
    let currentWeek = "";
    let selectedWeek = "";


    onMount(async () => {
        await fetchGamesForWeek(); // Fetch without week_num to get current week's data
        checkAndUpdateOdds(currentWeek); // Trigger odds update after the initial page load
    });

    async function fetchGamesForWeek(week_num = "") {
        // Default week_num to empty for initial load
        loading = true; // Set loading to true before fetching
        error = null; // Reset error state
        games = []; // Reset games state to avoid stale data

        try {
            const url = week_num
                ? `https://api.taylorswayze.com/games/${week_num}`
                : "https://api.taylorswayze.com/games/";
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch games for week ${week_num}: ${response.status}`,
                );
            }

            const data = await response.json();
            console.log("Fetched data:", data); // Log the full response data for debugging

            // Set the games and weeks if valid data is returned
            games = data.games || [];
            weeks = data.weeks || []; // Set weeks from API or default to empty array
            currentWeek = data.current_week; // Set the current week from the API response
            selectedWeek = currentWeek; // Default the selected week to the current week
        } catch (err) {
            console.error("Error fetching games:", err);
            error = err.message; // Set the error state
        } finally {
            loading = false; // Hide loading state after data is fetched
        }
    }

    async function checkAndUpdateOdds(week_num) {
        try {
            const response = await fetch(
                `https://api.taylorswayze.com/update-odds/${week_num}`,
            );
            if (!response.ok) {
                throw new Error(
                    `Failed to update odds for week ${week_num}: ${response.status}`,
                );
            }

            const updatedData = await response.json();
            console.log("Fetched fresh odds:", updatedData);

            // Ensure the response contains games before proceeding
            if (updatedData.games && updatedData.games.length > 0) {
                console.log("Refreshed data returned and valid");

                // Create a copy of the current games array and update it
                games = games.map((game) => {
                    // Find the updated game with matching event_id
                    const updatedGame = updatedData.games.find(
                        (updated) => updated.event_id === game.event_id,
                    );

                    // If an updated game is found, update the odds and odds_last_updated
                    if (updatedGame) {
                        return {
                            ...game,
                            odds: updatedGame.odds || "N/A", // Use updated odds or default to 'N/A'
                            odds_last_updated:
                                updatedGame.odds_last_updated || "N/A", // Use updated date or default to 'N/A'
                        };
                    }
                    // Return the original game if no update is found
                    return game;
                });
            }
        } catch (err) {
            console.error("Error updating odds:", err);
        }
    }

    $: {
        if (games.length > 0) {
            console.log("Games have been updated:", games);
        }
    }

    function handleWeekChange(event) {
        selectedWeek = event.target.value;
        console.log("Selected week:", selectedWeek); // Debugging: log the selected week number

        if (selectedWeek !== currentWeek) {
            fetchGamesForWeek(selectedWeek); // Fetch games for the selected week
            checkAndUpdateOdds(selectedWeek); // Trigger odds update for the selected week
        }
    }

    async function fetchTeamSchedule(team_id) {
    try {
        console.log('Called team schedule')
        const response = await fetch(`https://api.taylorswayze.com/team-schedule/${team_id}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        games = data.schedule;
      } else {
        console.error('Failed to fetch team schedule:', response.status);
      }
    } catch (error) {
      console.error('Error fetching team schedule:', error);
    }
  }

  // This function gets called when a user clicks on a team
  function handleTeamClick(team_id) {
    fetchTeamSchedule(team_id);
  }

</script>

<div class="app">
    <!-- Week Selector -->
    <div class="week-selector">
        <label for="week">Select Week:</label>
        <select
            id="week"
            bind:value={selectedWeek}
            on:change={handleWeekChange}
        >
            {#each weeks as week}
                <option value={week} selected={week == currentWeek}
                    >{week}</option
                >
            {/each}
        </select>
    </div>

    <!-- Game List -->
    <div class="game-list">
        {#if loading}
            <p>Loading games...</p>
        {:else if error}
            <p>Error: {error}</p>
        {:else if games.length > 0}
            <ul>
                <sub class="last-updated"
                    >Odds last updated: {games[0].odds_last_updated}</sub
                >
                {#each games as game}
                    <div class="game-card">
                        <li>
                            <div class="team-row">
                                <div class="team-box">
                                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>
                                    <img
                                        class="team-logo"
                                        src="./logos/{game.away_team_logo}"
                                        alt={game.away_team}
                                    />
                                    </a>

                                    <div class="team-name">
                                        <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.away_team_id); }}>
                                            {game.away_team}
                                          </a>
                                    </div>
                                    <div class="win-prob">
                                        ({game.away_team_record})<br>
                                        Win Prob: {game.away_win_prob}%
                                    </div>
                                </div>

                                <div class="vs-text">vs.</div>

                                <div class="team-box">
                                    <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>
                                    <img
                                        class="team-logo"
                                        src="./logos/{game.home_team_logo}"
                                        alt={game.home_team}
                                    />
                                    </a>
                                    <div class="team-name">
                                        <a href="#" on:click={(e) => { e.preventDefault(); handleTeamClick(game.home_team_id); }}>
                                            {game.home_team}
                                            </a>
                                    </div>
                                    <div class="win-prob">
                                        ({game.home_team_record})<br>
                                        Win Prob: {game.home_win_prob}%
                                    </div>
                                </div>
                            </div>

                            <div class="game-info">
                                {game.game_datetime}<br />{game.odds}
                            </div>
                        </li>
                    </div>
                {/each}
            </ul>
        {:else}
            <p>No games available.</p>
        {/if}
    </div>
</div>
